////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 254, 254, 1);
}
.bg-secondaryColor {
  background-color: rgba(140, 61, 87, 1);
}
.bg-thirdColor {
  background-color: rgba(140, 61, 87, 1);
}
.bg-fourthColor {
  background-color: rgba(100, 43, 115, 1);
}
.bg-fifthColor {
  background-color: rgba(185, 77, 213, 1);
}
.bg-sixthColor {
  background-color: rgba(255, 254, 254, 1);
}
.bg-seventhColor {
  background-color: rgba(255, 254, 254, 1);
}
